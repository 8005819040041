/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from "react";
import { AspectRatio, Rating, Tabs, Text } from "@mantine/core";
import {
  IconBook,
  IconCheck,
  IconDatabase,
  IconFileDollar,
  IconGift,
  IconPencil,
  IconSend,
  IconServerBolt,
  IconSettings,
  IconStack3,
  IconThumbUp,
  IconUsers,
} from "@tabler/icons-react";
import { formatDistanceToNow } from "date-fns";

import "./landing.css";
import {
  authDetails,
  awsServices,
  bestPractices,
  blogDetails,
  databaseDetails,
  emailDetails,
  generalDetails,
  moreDetails,
  paymentDetails,
  techStack,
} from "./util";

const { VITE_STAGE_DOMAIN } = import.meta.env;

const Hero = () => {
  const handlePricingButtonClick = () => {
    const pricingSection = document.getElementById("pricing")!;
    pricingSection.scrollIntoView({ behavior: "smooth" });
  };

  const deadline = new Date("2025-02-17T00:00:00"); // Specify your deadline here
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  const [lastUpdated, setLastUpdated] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    handleResize(); // Check initial width
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const [remainingTime, setRemainingTime] = useState("23:10:00");

  useEffect(() => {
    const fetchLastUpdatedDate = async () => {
      try {
        const response = await fetch(
          `https://api.${VITE_STAGE_DOMAIN}/v1/user/landing/last-updated`
        );

        const data = await response.json();
        setLastUpdated(data.lastUpdatedAt);
      } catch (error) {
        console.error("Error fetching last updated date:", error);
      }
    };

    fetchLastUpdatedDate();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    // Clear interval on component unmount to avoid memory leaks
    return () => clearInterval(interval);
  }, []);

  function calculateRemainingTime() {
    const now = new Date();
    const deadlineDate = new Date(deadline); // Convert deadline to a Date object
    let totalSeconds = Math.max(
      (deadlineDate.getTime() - now.getTime()) / 1000,
      0
    ); // Use getTime() to get the number of milliseconds since January 1, 1970

    const days = Math.floor(totalSeconds / (24 * 3600));
    totalSeconds %= 24 * 3600;
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return { days, hours, minutes, seconds };
  }

  const formatTime = (value: any, label: any) => {
    return (
      <div className="time-box">
        <div className="time-value">{value}</div>
        <div className="time-label">{label}</div>
      </div>
    );
  };

  const formatLastUpdated = () => {
    if (lastUpdated) {
      const formattedDate = formatDistanceToNow(new Date(lastUpdated), {
        addSuffix: true,
      });
      return formattedDate;
    }
    return "";
  };

  return (
    <section id="hero">
      <div className="hero-testimonials">
        {!isMobile ? (
          <>
            <div className="hero-testimonial-star">
              <Rating defaultValue={5} readOnly size={isMobile ? "xs" : "sm"} />
              <Text
                size={isMobile ? "sm" : "xl"}
                align="center"
                m="auto"
                mb="xl"
                maw={700}
              >
                "Great kit, easy to use"
              </Text>
            </div>
            <div className="hero-testimonial-star">
              <Rating defaultValue={5} readOnly size={isMobile ? "xs" : "sm"} />
              <Text
                size={isMobile ? "sm" : "xl"}
                align="center"
                m="auto"
                mb="xl"
                maw={700}
              >
                "A fantastic kit"
              </Text>
            </div>
            <div className="hero-testimonial-star">
              <Rating defaultValue={5} readOnly size={isMobile ? "xs" : "sm"} />
              <Text
                size={isMobile ? "sm" : "xl"}
                align="center"
                m="auto"
                mb="xl"
                maw={700}
              >
                "Saved me a lot of time"
              </Text>
            </div>
          </>
        ) : (
          <>
            <div className="hero-testimonial-star">
              <Rating defaultValue={5} readOnly size={isMobile ? "xs" : "sm"} />
              <Text
                size={isMobile ? "sm" : "xl"}
                align="center"
                m="auto"
                mb="xl"
                maw={700}
              >
                "Saved me a lot of time"
              </Text>
            </div>
          </>
        )}
      </div>
      <div className="hero-container">
        <div className="hero-container-a">
          <div className="hero">
            <h1 className="title">
              {/* Build your SaaS <br />
              <span
                style={{
                  textDecoration: "line-through",
                }}
              >
                in months
              </span>{" "}
              in days */}
              {/* Deploy your app in minutes with serverless architecture */}
              Frontend, Backend and Infra - all in one
            </h1>
            <h2 className="description ">
              Save time and money with the production-ready AWS serverless kit
              using{" "}
              <span className="hero-title-highlight-text"> best practices</span>
            </h2>

            <div className="button-container">
              <button
                className="btn-primary"
                onClick={handlePricingButtonClick}
              >
                Buy now with 40% off
              </button>
            </div>

            <div className="gift-box">
              <div className="offer-box">
                <IconGift className="gift-icon" size="1.5rem" />{" "}
                <span className="discount-100">40% off </span> until
              </div>
              {/* <span className="cooldown-time"> {remainingTime}</span>. */}
              <div className="cooldown-time">
                {formatTime(remainingTime.days, "days")}{" "}
                {formatTime(remainingTime.hours, "hours")}{" "}
                {formatTime(remainingTime.minutes, "minutes")}{" "}
                {formatTime(remainingTime.seconds, "seconds")}
              </div>
            </div>

            <div
              className="senja-embed hero-testimonial"
              data-id="f5ef400a-2abf-45b2-a885-16ee9abc2d4c"
              data-lazyload="false"
            ></div>

            <div className="last-update-box">
              {lastUpdated && (
                <p className="last-update">
                  Main branch updated: {formatLastUpdated()}
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="hero-container-b">
          <div className="hero-video">
            <AspectRatio ratio={16 / 9}>
              <iframe
                src="https://www.youtube.com/embed/2uh7gHW_hOs?si=g30j2wBB846_FUcG"
                title="Deploy your app in minutes with serverless architecture"
                style={{ border: 0 }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              />
            </AspectRatio>
          </div>
        </div>
      </div>

      <div className="hero-tabs">
        <Tabs color="cyan" variant="pills" radius="md" defaultValue="general">
          <Tabs.List>
            <Tabs.Tab value="general" icon={<IconPencil size="0.8rem" />}>
              General
            </Tabs.Tab>
            <Tabs.Tab value="tech-stack" icon={<IconStack3 size="0.8rem" />}>
              Tech Stack
            </Tabs.Tab>
            <Tabs.Tab value="payment" icon={<IconFileDollar size="0.8rem" />}>
              Payment
            </Tabs.Tab>
            <Tabs.Tab value="email" icon={<IconSend size="0.8rem" />}>
              Email
            </Tabs.Tab>
            <Tabs.Tab value="auth" icon={<IconUsers size="0.8rem" />}>
              Auth
            </Tabs.Tab>
            <Tabs.Tab value="blog" icon={<IconBook size="0.8rem" />}>
              Blog
            </Tabs.Tab>
            <Tabs.Tab value="database" icon={<IconDatabase size="0.8rem" />}>
              Database
            </Tabs.Tab>
            <Tabs.Tab
              value="best-practices"
              icon={<IconThumbUp size="0.8rem" />}
            >
              Best practices
            </Tabs.Tab>
            <Tabs.Tab
              value="aws-services"
              icon={<IconServerBolt size="0.8rem" />}
            >
              AWS Services
            </Tabs.Tab>
            <Tabs.Tab value="more" icon={<IconSettings size="0.8rem" />}>
              More
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="general" pt="xs">
            <div className="check-lists">
              <div className="check-list" key="no-overhead">
                <IconCheck className="check-list-icon" />
                <p
                  className="check-list-text"
                  style={{
                    textDecoration: "underline",
                  }}
                >
                  No third-party services, no boilerplate headache, no hidden
                  costs,
                </p>
              </div>
              {generalDetails.map((gen) => (
                <div className="check-list" key={gen}>
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{gen}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="tech-stack" pt="xs">
            <div className="check-lists">
              {techStack.map((tech) => (
                <div className="check-list" key={tech}>
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{tech}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="payment" pt="xs">
            <div className="check-lists">
              {paymentDetails.map((detail) => (
                <div className="check-list">
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{detail}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="blog" pt="xs">
            <div className="check-lists">
              {blogDetails.map((detail) => (
                <div className="check-list">
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{detail}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="email" pt="xs">
            <div className="check-lists">
              {emailDetails.map((detail) => (
                <div className="check-list">
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{detail}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="aws-services" pt="xs">
            <div className="check-lists">
              {awsServices.map((service) => (
                <div className="check-list" key={service}>
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{service}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="auth" pt="xs">
            <div className="check-lists">
              {authDetails.map((detail) => (
                <div className="check-list">
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{detail}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="database" pt="xs">
            <div className="check-lists">
              {databaseDetails.map((detail) => (
                <div className="check-list">
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{detail}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="best-practices" pt="xs">
            <div className="check-lists">
              {bestPractices.map((detail) => (
                <div className="check-list">
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{detail}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>

          <Tabs.Panel value="more" pt="xs">
            <div className="check-lists">
              {moreDetails.map((detail) => (
                <div className="check-list">
                  <IconCheck className="check-list-icon" />
                  <p className="check-list-text">{detail}</p>
                </div>
              ))}
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
    </section>
  );
};

export default Hero;
